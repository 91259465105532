import React from 'react';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { ServicePoint } from 'common/src/app/data/enum/ServicePointsInfo';
import { groupEnquiriesNumber } from 'common/src/app/config/market/market.configdefinitions';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';

import TextNew, {
  Colors,
  ElementTypes,
  FontFamily,
  FontSizes,
  FontWeight,
} from '../../atoms/TextNew';
import OpeningTimes from '../../atoms/OpeningTimes';
import NearestGroupSearchBar from '../../organisms/NearestGroupSearchBar';

import './contact-us-group-enquiries.scss';

const ContactUsGroupEnquiries = ({}, _context: any, className: string, dataTestid: string) => (
  <section className={className} data-testid={dataTestid}>
    <div className="title-and-phone-number-wrapper">
      <TextNew
        localeId="contactUsGroupEnquiries.title"
        family={FontFamily.SERIF}
        size={FontSizes.SM}
        element={ElementTypes.H2}
        color={Colors.BRAND}
        cid="contact-section-title"
      />

      {/* get the phone number from the market config:
    - this ensures that we will get the correct one, for:
    - UK and IE */}

      <a href={`tel:${groupEnquiriesNumber}`} className="phone-number-link">
        <TextNew
          family={FontFamily.SANS}
          size={FontSizes.SM}
          weight={FontWeight.LIGHT}
          color={Colors.BRAND}
        >
          {groupEnquiriesNumber}
        </TextNew>
      </a>
    </div>

    <OpeningTimes servicePoint={ServicePoint.GROUP_ENQUIRIES} />

    <TextNew
      localeId="contactUsGroupEnquiries.body.info"
      family={FontFamily.SANS}
      size={FontSizes.SM}
      weight={FontWeight.LIGHT}
      key="group-enquiries-body-text"
      localeParams={{
        FOCUS: (
          <TextNew
            key="group-enquiries-focus-text"
            weight={FontWeight.NORMAL}
            localeId="contactUsGroupEnquiries.body.focus"
            element={ElementTypes.SPAN}
          />
        ),
      }}
    />
    <NearestGroupSearchBar hideIntro />
  </section>
);

export default withFunctionalClassName(
  ComponentType.MOLECULE,
  'ContactUsGroupEnquiries',
)(ContactUsGroupEnquiries);
