export const ServicePoint = {
  GROUP_ENQUIRIES: 'groupEnquiries',
  HEAD_OFFICE: 'headOffice',
  ADVERTISING: 'advertising',
  PHONE: 'phone',
  LIVE_CHAT: 'liveChat',
} as const;

export const OpeningDays = {
  MON_TO_FRI: 'monToFri',
  MON_TO_THURS: 'monToThurs',
  FRIDAY: 'friday',
  SAT_SUN_BANK: 'satSunBank',
} as const;

export const ServicePointDetails = {
  [ServicePoint.GROUP_ENQUIRIES]: {
    phoneNumber: '0344 897 8000',
    openingTimes: {
      [OpeningDays.MON_TO_FRI]: {
        opening: '9',
        closing: '18',
      },
      [OpeningDays.SAT_SUN_BANK]: {
        opening: '9',
        closing: '14',
      },
    },
  },
  [ServicePoint.HEAD_OFFICE]: {
    latLngLocation: { lat: 53.090374, lng: -1.349802 },
    phoneNumber: '0344 892 0400',
    openingTimes: {
      [OpeningDays.MON_TO_FRI]: {
        opening: '9',
        closing: '18',
      },
      [OpeningDays.SAT_SUN_BANK]: {
        opening: '9',
        closing: '14',
      },
    },
  },
  [ServicePoint.ADVERTISING]: {
    phoneNumber: '0344 892 0435',
    openingTimes: {
      [OpeningDays.MON_TO_FRI]: {
        opening: '9',
        closing: '17',
      },
    },
  },
  [ServicePoint.LIVE_CHAT]: {
    openingTimes: {
      [OpeningDays.MON_TO_THURS]: {
        opening: '9',
        closing: '21',
      },
      [OpeningDays.FRIDAY]: {
        opening: '9',
        closing: '18',
      },
      [OpeningDays.SAT_SUN_BANK]: {
        opening: '9',
        closing: '17',
      },
    },
  },
  [ServicePoint.PHONE]: {
    openingTimes: {
      [OpeningDays.MON_TO_FRI]: {
        opening: '9',
        closing: '18',
      },
      [OpeningDays.SAT_SUN_BANK]: {
        opening: '9',
        closing: '17',
      },
    },
  },
};
