import React from 'react';
import { onlineMemberSupportPhoneNumber } from 'common/src/app/config/market/market.configdefinitions';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';

import { ServicePoint } from 'common/src/app/data/enum/ServicePointsInfo';
import OpeningTimes from '../OpeningTimes';

import TextNew, { FontFamily, FontSizes, FontWeight, Colors, ElementTypes } from '../TextNew';

import './contact-us-online-member-support.scss';

const ContactUsOnlineMemberSupport = ({}, _context: any, className: string, dataTestid: string) => (
  <section className={className} data-testid={dataTestid}>
    <TextNew
      localeId="contactUsOnlineMemberSupport.title"
      family={FontFamily.SERIF}
      size={FontSizes.SM}
      element={ElementTypes.H2}
      color={Colors.BRAND}
      cid="contact-section-title"
    />

    <TextNew
      localeId="contactUsOnlineMemberSupport.intro.digitalSupport"
      family={FontFamily.SANS}
      size={FontSizes.SM}
      cid="digital-support-intro"
    />

    <TextNew
      localeId="contactUsOnlineMemberSupport.liveChat.header"
      family={FontFamily.SANS}
      size={FontSizes.MD}
      element={ElementTypes.H3}
      color={Colors.BRAND}
      cid="contact-section-title"
    />

    <TextNew
      localeId="contactUsOnlineMemberSupport.liveChat.intro"
      family={FontFamily.SANS}
      size={FontSizes.SM}
      weight={FontWeight.LIGHT}
      localeParams={{
        LIVE_CHAT_TRIGGER: (
          <TextNew
            localeId="contactUsOnlineMemberSupport.liveChat.trigger"
            family={FontFamily.SANS}
            weight={FontWeight.NORMAL}
            element={ElementTypes.SPAN}
          />
        ),
      }}
    />

    <OpeningTimes servicePoint={ServicePoint.LIVE_CHAT} />

    <TextNew
      localeId="contactUsOnlineMemberSupport.phone.header"
      family={FontFamily.SANS}
      size={FontSizes.MD}
      element={ElementTypes.H3}
      color={Colors.BRAND}
      cid="contact-section-title"
    />

    <TextNew
      localeId="contactUsOnlineMemberSupport.phone.body"
      family={FontFamily.SANS}
      size={FontSizes.SM}
      weight={FontWeight.LIGHT}
      localeParams={{
        PHONE_NUMBER: (
          <a href={`tel:${onlineMemberSupportPhoneNumber}`} className="phone-number-link">
            <TextNew
              family={FontFamily.SANS}
              size={FontSizes.SM}
              element={ElementTypes.SPAN}
              weight={FontWeight.LIGHT}
              color={Colors.BRAND}
            >
              {onlineMemberSupportPhoneNumber}
            </TextNew>
          </a>
        ),
      }}
    />

    <OpeningTimes servicePoint={ServicePoint.PHONE} />
  </section>
);

export default withFunctionalClassName(
  ComponentType.ATOM,
  'ContactUsOnlineMemberSupport',
)(ContactUsOnlineMemberSupport);
