import { compose, AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { prepareComponent, withInitAction } from '@mediamonks/react-redux-component-init';
import ContactUsGetInTouch from './ContactUsGetInTouch';
import * as ContactUsForm from '../../organisms/ContactUsForm';

const addInitAction = withInitAction({
  prepared: ({}, dispatch: ThunkDispatch<unknown, void, AnyAction>) =>
    dispatch(prepareComponent(ContactUsForm.Public)),
});

export default compose(addInitAction)(ContactUsGetInTouch);
