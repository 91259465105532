import { ContactFormSubjectIds } from 'common/src/app/data/enum/ContactFormCategory';

export const ContactUsType = {
  DEFAULT: 'default',
  ADVERTISEMENT: 'advertisement',
  MEDIA: 'media',
  // used to make the get in touch tab active without automatically scrolling to
  // the email form and preselecting a category
  GET_IN_TOUCH: 'get-in-touch',
} as const;

export const ContactUsTypeId = {
  [ContactUsType.ADVERTISEMENT]: ContactFormSubjectIds.ADVERTISEMENT_ARTWORK,
};

export default ContactUsType;
