import React from 'react';
import { compose } from 'redux';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { ContactUsType } from 'common/src/app/data/enum/ContactUsType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import { marketName } from 'common/src/app/config/market/market.configdefinitions';

import ContactUsHeadOffice from '../../atoms/ContactUsHeadOffice';
import ContactUsOnlineMemberSupport from '../../atoms/ContactUsOnlineMemberSupport';
import ContactUsGroupEnquiries from '../../molecules/ContactUsGroupEnquiries';
import ContactUsIpso from '../../molecules/ContactUsIpso';

import * as ContactUsForm from '../../organisms/ContactUsForm';

import './contact-us-get-in-touch.scss';

const ContactUsGetInTouch = (
  { contactType }: { contactType: typeof ContactUsType[keyof typeof ContactUsType] },
  _context: any,
  className: string,
  dataTestid: string,
) => (
  <div className={className} data-testid={dataTestid}>
    <ContactUsGroupEnquiries />
    {((marketName as unknown) as string) === 'UK' ? <ContactUsOnlineMemberSupport /> : null}
    <ContactUsHeadOffice />
    <ContactUsForm.Public contactType={contactType} />
    <ContactUsIpso />
  </div>
);

export default compose(withFunctionalClassName(ComponentType.TEMPLATE, 'ContactUsGetInTouch'))(
  ContactUsGetInTouch,
);
