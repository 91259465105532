import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import ContactUsFieldNames from 'common/src/app/data/enum/FieldNames/ContactUsFieldNames';
import {
  ContactFormSubjectIds,
  getSubjectOptions,
} from 'common/src/app/data/enum/ContactFormCategory';
import InputType from 'common/src/app/data/enum/InputType';
import { ContactUsType } from 'common/src/app/data/enum/ContactUsType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import Textarea from '../../atoms/Textarea';
import Button from '../../atoms/Button';
import Select from '../../atoms/Select';
import FormikError from '../../atoms/FormikError';
import TextNew, { Colors, ElementTypes, FontWeight } from '../../atoms/TextNew';
import ContactUsIcelandConfirm from '../../atoms/ContactUsIcelandConfirm';
import ContactUsSuccessMessage from '../../molecules/ContactUsSuccessMessage';

import ContactUsPublicForm from './components/molecules/ContactUsPublicForm';

import './contact-us-form.scss';

const ContactUsForm = (
  {
    values,
    successMessageSubjectId,
    contactType,
    errors,
    children,
    handleSubmit,
    handleChange,
    handleBlur,
    scrollToElement,
    setSuccessMessage,
    hasErrorBoundary,
    hasContactType,
    isValid,
    isSubmitting,
    loading,
  },
  { getMessage },
  className,
  dataTestId,
) => {
  const contactFormRef = useRef(null);

  const { contactFormCategory, icelandTerms } = values;
  let localeMessageType = '';

  const selectOptions = [
    { title: getMessage('contactUsForm.placeholder.subject'), value: '' },
    ...getSubjectOptions({ getMessage }),
  ];

  if (contactFormCategory === ContactFormSubjectIds.ADVERTISEMENT_ARTWORK) {
    localeMessageType = 'Advertisement';
  }

  useEffect(() => {
    const onLoad = () => {
      if (contactFormRef.current && contactType !== ContactUsType.GET_IN_TOUCH) {
        scrollToElement(contactFormRef.current, 0, false);
      }
    };

    window.addEventListener('load', onLoad);

    return () => {
      window.removeEventListener('load', onLoad);
      setSuccessMessage && setSuccessMessage(null);
    };
  }, []);

  return (
    <div className={className} ref={contactFormRef} data-testid={dataTestId}>
      {successMessageSubjectId ? (
        <header>
          <ContactUsSuccessMessage subjectId={successMessageSubjectId} />
        </header>
      ) : (
        <>
          <TextNew.Sans.SM
            weight={FontWeight.LIGHT}
            localeId="contactUsForm.body"
            localeParams={{
              PREFER: (
                <TextNew.Sans.SM
                  key="contact-us-prefer-email"
                  weight={FontWeight.NORMAL}
                  localeId="contactUsForm.prefer"
                  element={ElementTypes.SPAN}
                />
              ),
            }}
          />
          <form onSubmit={handleSubmit}>
            <Select
              customLabel={getMessage('contactUsForm.placeholder.subject')}
              defaultValue={contactFormCategory}
              dataTestid={`${dataTestId}-select`}
              onChange={handleChange}
              name={ContactUsFieldNames.CONTACT_FORM_CATEGORY}
              options={selectOptions}
              disabled={hasErrorBoundary || hasContactType}
            />
            {contactFormCategory === ContactFormSubjectIds.SW_ICELAND && (
              <ContactUsIcelandConfirm value={icelandTerms} handleChange={handleChange} />
            )}
            {contactFormCategory !== '' && (
              <>
                {children}
                <Textarea
                  data-testid={`${dataTestId}-message`}
                  rows="4"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name={ContactUsFieldNames.MESSAGE}
                  type={InputType.TEXT}
                  placeholder={getMessage(`contactUsForm.contactMessage${localeMessageType}`)}
                />
                <FormikError
                  dataTestid={`${dataTestId}-error`}
                  name={ContactUsFieldNames.MESSAGE}
                />
                <Button
                  type={InputType.SUBMIT}
                  dataTestid={`${dataTestId}-button`}
                  disabled={!isValid || isSubmitting}
                  isLoading={isSubmitting || loading}
                >
                  <TextNew.Sans.SM
                    element={ElementTypes.SPAN}
                    color={Colors.PRIMARY_LIGHT}
                    localeId="contactUsForm.cta"
                  />
                </Button>
              </>
            )}
          </form>
        </>
      )}

      {errors?.generalError && (
        <TextNew.Error localeId={errors.generalError} cid="error" color={Colors.BRAND} />
      )}
    </div>
  );
};

ContactUsForm.defaultProps = {
  hasContactType: false,
};

ContactUsForm.propTypes = {
  children: PropTypes.node,
  handleSubmit: PropTypes.func,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  values: PropTypes.object,
  errors: PropTypes.object,
  successMessageSubjectId: PropTypes.string,
  setSuccessMessage: PropTypes.func,
  loading: PropTypes.bool,
  hasErrorBoundary: PropTypes.bool,
  hasContactType: PropTypes.bool,
  scrollToElement: PropTypes.func,
  contactType: PropTypes.string,
  isValid: PropTypes.bool,
  isSubmitting: PropTypes.bool,
};

ContactUsForm.contextTypes = {
  getMessage: PropTypes.func.isRequired,
};

export { ContactUsPublicForm };

export default withFunctionalClassName(ComponentType.ORGANISM, 'ContactUsForm')(ContactUsForm);
