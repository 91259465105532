import React from 'react';
import moment from 'moment';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import {
  ServicePoint,
  ServicePointDetails,
  OpeningDays,
} from 'common/src/app/data/enum/ServicePointsInfo';
import TextNew, { FontFamily, FontSizes, FontWeight } from '../TextNew';

import './opening-times.scss';

type ServicePointValues = typeof ServicePoint[keyof typeof ServicePoint];
type OpeningDayType = typeof OpeningDays[keyof typeof OpeningDays];

const OpeningTimes = (
  { servicePoint }: { servicePoint: ServicePointValues },
  _context: any,
  className: string,
  dataTestid: string,
) => {
  const formatTime = (time: 'opening' | 'closing', openingDay: OpeningDayType) => {
    const departmentTimes: string = (ServicePointDetails[servicePoint].openingTimes as Record<
      OpeningDayType,
      {
        opening: string;
        closing: string;
      }
    >)[openingDay][time];
    const [hour, minute = 0] = departmentTimes.split(':') || '';
    return moment([0, 0, 1, hour, minute, 0, 0]).format(`h${minute ? ':m' : ''}a`);
  };

  return (
    <div data-testid={dataTestid} className={className}>
      {Object.keys(ServicePointDetails?.[servicePoint]?.openingTimes).map(openingDay => (
        <div key={openingDay} className="opening-hours">
          <TextNew
            localeId={`openingTimes.openingDays.${openingDay}`}
            family={FontFamily.SANS}
            size={FontSizes.SM}
            weight={FontWeight.LIGHT}
            dataTestid={`${dataTestid}-days`}
          />
          <TextNew
            family={FontFamily.SANS}
            size={FontSizes.SM}
            weight={FontWeight.LIGHT}
            cid="hours"
            dataTestid={`${dataTestid}-times`}
          >
            {`${formatTime('opening', openingDay as OpeningDayType)} – ${formatTime(
              'closing',
              openingDay as OpeningDayType,
            )}`}
          </TextNew>
        </div>
      ))}
    </div>
  );
};

export { ServicePoint, OpeningDays, ServicePointDetails };

export default withFunctionalClassName(ComponentType.ATOM, 'OpeningTimes')(OpeningTimes);
