import React from 'react';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { ServicePoint } from 'common/src/app/data/enum/ServicePointsInfo';
import { headOfficeNumber } from 'common/src/app/config/market/market.configdefinitions';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import TextNew, {
  Colors,
  FontFamily,
  FontSizes,
  FontWeight,
  ElementTypes,
} from '../../atoms/TextNew';

import OpeningTimes from '../OpeningTimes';

import './contact-us-head-office.scss';

const ContactUsHeadOffice = ({}, _context: any, className: string, dataTestid: string) => (
  <section className={className} data-testid={dataTestid}>
    <TextNew
      size={FontSizes.SM}
      family={FontFamily.SERIF}
      localeId="contactUsHeadOffice.title"
      element={ElementTypes.H2}
      color={Colors.BRAND}
      cid="contact-section-title"
    />

    <TextNew
      localeId="contactUsHeadOffice.address.intro"
      size={FontSizes.SM}
      family={FontFamily.SANS}
      weight={FontWeight.BOLD}
    />
    <TextNew
      localeId="contactUsHeadOffice.address.address"
      element={ElementTypes.ADDRESS}
      size={FontSizes.SM}
      family={FontFamily.SANS}
      weight={FontWeight.LIGHT}
      dataTestid="contact-us-head-office-address"
    />

    {/* get the phone number from the market config:
    - this ensures that we will get the correct one, for UK and for IE */}
    <TextNew
      localeId="contactUsHeadOffice.call.intro"
      size={FontSizes.SM}
      family={FontFamily.SANS}
      weight={FontWeight.BOLD}
      cid="contact-us-head-office-number"
    >
      <a href={`tel:${headOfficeNumber}`} className="phone-number-link">
        <TextNew
          family={FontFamily.SANS}
          size={FontSizes.SM}
          element={ElementTypes.SPAN}
          weight={FontWeight.LIGHT}
          color={Colors.BRAND}
        >
          {headOfficeNumber}
        </TextNew>
      </a>
    </TextNew>

    <OpeningTimes servicePoint={ServicePoint.HEAD_OFFICE} />
  </section>
);

export default withFunctionalClassName(
  ComponentType.ATOM,
  'ContactUsHeadOffice',
)(ContactUsHeadOffice);
