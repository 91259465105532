import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from 'components/atoms/Button';
import { getLinkPropsFromUrl } from 'common/src/app/util/urlUtils';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import videoPropTypes from 'common/src/app/util/proptypes/apiEntities/video';
import ImagePropTypes from 'common/src/app/util/proptypes/ImagePropTypes';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import Link from 'react-router/lib/Link';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import TextNew, {
  FontWeight,
  FontSizes,
  ElementTypes,
  Colors,
  AccentColors,
} from 'components/atoms/TextNew';
import TitledBlock from 'components/atoms/TitledBlock';
import HeroBarPublic from 'components/organisms/HeroBarPublic';
import Wrapper from 'components/atoms/Wrapper';
import MasterTile from 'components/organisms/MasterTile';
import FullWidthInformationBar from 'components/organisms/FullWidthInformationBar';
import RichTextBlock from 'components/molecules/BlockContent/components/atoms/RichTextBlock';
import Pages from 'common/src/app/data/enum/Pages';
import Icon from 'components/atoms/Icon';
import IconName from 'common/src/app/data/enum/IconName';
import errorBoundary from 'components/hoc/ErrorBoundary';
import VideoWithThumbnailBlock from 'components/molecules/BlockContent/components/atoms/VideoWithThumbnailBlock';
import NewsletterSignUp from '../../molecules/NewsletterSignUp';
import SuccessStoryInspiration from '../../molecules/SuccessStoryInspiration';

import './what-happens-in-group.scss';

const FloatingImages = ({ featuredStories }) => (
  <div className="image-wrapper">
    <Link
      to={`${Pages.UK_PUBLIC_SUCCESS_STORIES}/${featuredStories.slug}`}
      data-testid="featured-success-story-link"
    >
      <div
        className="person-before-image"
        style={{ backgroundImage: `url(${featuredStories.beforeImage})` }}
        aria-label="Before image"
      />
      <div
        className="person-after-image"
        style={{ backgroundImage: `url(${featuredStories.afterImage})` }}
        aria-label="After image"
      />
    </Link>
  </div>
);

FloatingImages.propTypes = {
  featuredStories: PropTypes.shape({
    slug: PropTypes.string,
    beforeImage: PropTypes.string,
    afterImage: PropTypes.string,
  }),
};

const WhatHappensInGroup = (
  {
    deviceState,
    heroBar,
    groupFeatureCards,
    video,
    firstBanner,
    promiseTitle,
    promisePoints,
    featuredStories,
    secondBanner,
    successStoryLeft,
    successStoryRight,
  },
  context,
  className,
) => {
  const scriptSizes = deviceState >= DeviceState.MD ? FontSizes.XL : FontSizes.LG;

  return (
    <div className={className}>
      <HeroBarPublic image={heroBar.image}>
        <TextNew.Serif.SM
          cid="hero-bar-public-serif-heading"
          element={ElementTypes.H1}
          weight={FontWeight.BOLD}
        >
          {heroBar.title}
        </TextNew.Serif.SM>

        <TextNew.Script.MD
          cid="hero-bar-public-script-heading"
          color={Colors.BRAND}
          element={ElementTypes.H1}
        >
          {heroBar.subtitle}
        </TextNew.Script.MD>
        <RichTextBlock cid="hero-bar-public-introduction" text={heroBar.introduction} />
        <Button isGhostBrand cid="header-cta" {...getLinkPropsFromUrl(heroBar.buttonLink)}>
          <TextNew.Sans.MD element={ElementTypes.SPAN} color={Colors.BRAND}>
            {heroBar.buttonLabel}
          </TextNew.Sans.MD>
        </Button>
      </HeroBarPublic>

      <Wrapper.LG>
        <section className="three-steps-grid">
          {groupFeatureCards.map((groupCard, index) => (
            <div
              className={classNames('each-step', `step-${index}`)}
              key={groupCard.image.id}
              data-testid="each-step"
            >
              <MasterTile
                id={groupCard.image.id}
                customDescription={
                  <>
                    <TextNew.Serif.SM
                      color={AccentColors.ACCENT_PURPLE}
                      weight={FontWeight.BOLD}
                      cid="step-title"
                    >
                      {groupCard.title}
                    </TextNew.Serif.SM>
                    <RichTextBlock cid="step-description" text={groupCard.description} />
                  </>
                }
                image={groupCard.image}
              />
            </div>
          ))}
        </section>
      </Wrapper.LG>

      {video && (
        <VideoWithThumbnailBlock
          video={video.video}
          title={video.title}
          videoCaption={video.videoCaption}
        />
      )}

      <section className="information-bar-wrapper">
        <FullWidthInformationBar
          title={
            <>
              <TextNew.Serif.MD color={Colors.PRIMARY_LIGHT} cid="group-length-heading">
                {firstBanner.line1}
              </TextNew.Serif.MD>
              <TextNew.Script
                size={scriptSizes}
                cid="group-length-subtitle"
                color={Colors.PRIMARY_LIGHT}
              >
                {firstBanner.line2}
              </TextNew.Script>
            </>
          }
          content={
            <>
              <RichTextBlock cid="group-length-description" text={firstBanner.description} />
              <Link
                {...getLinkPropsFromUrl(firstBanner.buttonLink)}
                data-testid="group-information-link"
              >
                <TextNew.Sans.SM color={Colors.PRIMARY_LIGHT} element={ElementTypes.SPAN}>
                  {firstBanner.buttonLabel}
                </TextNew.Sans.SM>
              </Link>
            </>
          }
        />
      </section>

      <TitledBlock>
        <TextNew element={ElementTypes.H2}>
          <TextNew.Serif.MD color={AccentColors.ACCENT_PURPLE}>
            {promiseTitle.line1}
          </TextNew.Serif.MD>
          <TextNew.Script
            size={scriptSizes}
            color={AccentColors.ACCENT_PURPLE}
            cid="member-promise-main"
          >
            {promiseTitle.line2}
          </TextNew.Script>
          <TextNew.Serif.MD color={AccentColors.ACCENT_PURPLE} cid="member-promise-bottom">
            {promiseTitle.line3}
          </TextNew.Serif.MD>
        </TextNew>
      </TitledBlock>

      <Wrapper.MD cid="list">
        {promisePoints.map(promise => {
          const promiseKey = promise.featureItem
            .slice(3)
            .split(' ', 4)
            .join('-');
          return (
            <div className="promise" key={promiseKey} data-testid="promise-item">
              <Icon name={IconName.CHECKMARK} size="32" />
              <RichTextBlock cid="promise-item" text={promise.featureItem} />
            </div>
          );
        })}
      </Wrapper.MD>

      <section className="story-wrapper">
        <Wrapper width="lg" className="floaty-image">
          <div className="content">
            <TextNew.Script.SM cid="title" element={ElementTypes.H2}>
              {featuredStories.title}
            </TextNew.Script.SM>
            <TextNew.Serif.XS cid="inspire-description">
              {featuredStories.introduction}
            </TextNew.Serif.XS>
            <TextNew.Serif
              color={AccentColors.ACCENT_PINK}
              weight={FontWeight.BOLD}
              cid="inspiration"
              size={deviceState <= DeviceState.LG ? FontSizes.SM : FontSizes.XS}
            >
              {featuredStories.subtitle}
            </TextNew.Serif>
          </div>

          {deviceState > DeviceState.LG && <FloatingImages featuredStories={featuredStories} />}

          <div className="images-and-quote">
            {deviceState <= DeviceState.LG && <FloatingImages featuredStories={featuredStories} />}

            <div className="quote">
              <TextNew.Serif
                element={ElementTypes.BLOCKQUOTE}
                color={AccentColors.ACCENT_PINK}
                size={deviceState > DeviceState.MD ? FontSizes.SM : FontSizes.MD}
                cid="person-quote"
              >
                {featuredStories.quote}
              </TextNew.Serif>
              <TextNew.Serif
                weight="bold"
                cid="person-name"
                size={deviceState <= DeviceState.LG ? FontSizes.SM : FontSizes.XS}
              >
                {featuredStories.memberName}
              </TextNew.Serif>
            </div>
          </div>
        </Wrapper>
      </section>

      <FullWidthInformationBar
        customClass="money-back-guarantee"
        title={
          <>
            <TextNew.Serif.SM cid="moneyback-1" color={Colors.PRIMARY_LIGHT}>
              {secondBanner.line1}
            </TextNew.Serif.SM>
            <TextNew.Script size={scriptSizes} cid="moneyback-2" color={Colors.PRIMARY_LIGHT}>
              {secondBanner.line2}
            </TextNew.Script>
            <TextNew.Serif.SM cid="moneyback-3" color={Colors.PRIMARY_LIGHT}>
              {secondBanner.line3}
            </TextNew.Serif.SM>
          </>
        }
        content={
          <>
            <RichTextBlock cid="money-back-description" text={secondBanner.description} />
            <Button cid="header-cta" isGhostLight {...getLinkPropsFromUrl(secondBanner.buttonLink)}>
              <TextNew.Sans.SM element={ElementTypes.SPAN} color={Colors.PRIMARY_LIGHT}>
                {secondBanner.buttonLabel}
              </TextNew.Sans.SM>
            </Button>
          </>
        }
      />
      {successStoryLeft && successStoryRight && (
        <SuccessStoryInspiration successStories={[successStoryLeft, successStoryRight]} />
      )}

      <Wrapper.LG>
        <NewsletterSignUp />
      </Wrapper.LG>
    </div>
  );
};

WhatHappensInGroup.propTypes = {
  deviceState: PropTypes.number,
  heroBar: PropTypes.shape({
    image: PropTypes.object,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    introduction: PropTypes.string,
    buttonLink: PropTypes.string,
    buttonLabel: PropTypes.string,
  }),
  groupFeatureCards: PropTypes.array,
  video: PropTypes.shape({
    title: PropTypes.string,
    video: PropTypes.shape({
      video: videoPropTypes.video.isRequired,
      videoThumbnail: ImagePropTypes,
      _type: PropTypes.string,
    }),
    videoCaption: PropTypes.string,
  }),
  firstBanner: PropTypes.shape({
    bannerTitleLine1: PropTypes.string,
    bannerTitleLine2: PropTypes.string,
    description: PropTypes.string,
    buttonLink: PropTypes.string,
    buttonLabel: PropTypes.string,
  }),
  promiseTitle: PropTypes.shape({
    line1: PropTypes.string,
    line2: PropTypes.string,
    line3: PropTypes.string,
  }),
  promisePoints: PropTypes.array,
  featuredStories: PropTypes.shape({
    title: PropTypes.string,
    introduction: PropTypes.string,
    subtitle: PropTypes.string,
    quote: PropTypes.string,
    memberName: PropTypes.string,
  }),
  secondBanner: PropTypes.shape({
    bannerTitleLine1: PropTypes.string,
    bannerTitleLine2: PropTypes.string,
    bannerTitleLine3: PropTypes.string,
    description: PropTypes.string,
    buttonLink: PropTypes.string,
    buttonLabel: PropTypes.string,
  }),
  successStoryLeft: PropTypes.object,
  successStoryRight: PropTypes.object,
};

export default compose(
  errorBoundary({ wholePageError: true }),
  withDeviceState(),
  withFunctionalClassName(ComponentType.PAGE, 'WhatHappensInGroup'),
)(WhatHappensInGroup);
