/* global 'organism','UkPublicFooter' */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Link from 'react-router/lib/Link';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import Pages from 'common/src/app/data/enum/Pages';
import linkPropTypes from 'common/src/app/util/proptypes/linkPropTypes';
import imageShape from 'common/src/app/util/proptypes/apiEntities/image';
import { marketName } from 'common/src/app/config/market/market.configdefinitions';
import PagesExternal from 'common/src/app/data/enum/PagesExternal';
import IconName from 'common/src/app/data/enum/IconName';
import { createPath } from 'common/src/app/util/routeUtils';
import TextNew, { FontWeight, AccentColors, Colors, ElementTypes } from '../../atoms/TextNew';
import { footerLinks, footerSubLinks } from '../../molecules/UkPublicNavigation/UkPublicLinks';
import ResponsiveImage from '../../atoms/ResponsiveImage';
import Wrapper from '../../atoms/Wrapper';
import SocialLinks from '../../molecules/SocialLinks';
import LocaleMessage from '../../atoms/LocaleMessage';
import Loader from '../../atoms/Loader';
import Button from '../../atoms/Button';
import Row from '../../atoms/Row';
import Icon from '../../atoms/Icon';

// TODO:
// This should really be using the public-footer - Umbraco data
// https://slimmingworlddigital.atlassian.net/browse/SWPUB-362
import './uk-public-footer.scss';

const UkPublicFooter = (
  { magazineImage, footerSocialLinks, isInitializing, host },
  context,
  className,
) => {
  const filteredSocialLinks = footerSocialLinks.filter(each => each.icon !== 'icon-pinterest');

  return (
    <footer className={className} id="footer" data-testid="footer" tabIndex="-1">
      <Wrapper.LG padding="sm">
        <div className="consultant-login">
          <Button isShadePink href={PagesExternal.OUR_WORLD_LOGIN}>
            <LocaleMessage id="footer.consultantLogin" />
          </Button>
        </div>

        <nav aria-label="Footer" className="footer-links">
          {isInitializing && <Loader />}
          {footerLinks(host, marketName).map((arrays, arrayIndex) => (
            <div key={`arrays-${arrayIndex}`} className={`footer-column`}>
              {arrays.map((col, index) => (
                <Fragment key={`col-${index}`}>
                  <TextNew.Sans.SM
                    color={Colors.PRIMARY_LIGHT}
                    cid="footer-col-title"
                    weight={FontWeight.BOLD}
                  >
                    {col.title}
                  </TextNew.Sans.SM>

                  {Object.keys(col.content).map((footerLinksItem, subLinkIndex) => (
                    <Link
                      data-testid={footerLinksItem}
                      key={col.content[footerLinksItem].title}
                      to={footerLinksItem}
                      target={col.content[footerLinksItem].target || null}
                    >
                      <TextNew.Sans.SM
                        weight={FontWeight.LIGHT}
                        color={Colors.PRIMARY_LIGHT}
                        cid={`${col.category}-${subLinkIndex}`}
                      >
                        {col.content[footerLinksItem].title}
                      </TextNew.Sans.SM>
                    </Link>
                  ))}
                </Fragment>
              ))}
            </div>
          ))}

          <div className="magazine">
            <div className="magazine-header">
              <TextNew.Serif.XS color={Colors.PRIMARY_LIGHT} localeId="footer.magazine.subtitle" />

              <TextNew.Script.SM
                color={Colors.PRIMARY_LIGHT}
                cid="magazine-title"
                localeId="footer.magazine.title"
              />
            </div>
            {magazineImage && (
              <ResponsiveImage
                data-testid="magazine-image"
                src={magazineImage.src}
                alt={magazineImage.alt}
                px={232}
              />
            )}
            <Button isPrimaryInverted cid="magazine-button" to={Pages.UK_PUBLIC_MAGAZINE}>
              <TextNew.Sans.SM
                element={ElementTypes.SPAN}
                color={AccentColors.ACCENT_PINK}
                weight={FontWeight.BOLD}
                localeId="footer.magazine.subscribe"
              />
            </Button>
          </div>
        </nav>

        <nav aria-label="Social media" className="socials-nav">
          <TextNew.Sans.XL
            cid="social-title"
            weight={FontWeight.BOLD}
            color={Colors.PRIMARY_LIGHT}
            localeId="footer.socialsTitle"
          />
          <SocialLinks size={32} type="monochrome" links={filteredSocialLinks} />
        </nav>

        <nav aria-label="General pages" className="general-pages">
          {Object.keys(footerSubLinks).map(page => {
            if (page === Pages.COOKIESPOLICY) {
              return (
                <button id="ot-sdk-btn" className="ot-sdk-show-settings" key={page}>
                  {footerSubLinks[page]}
                </button>
              );
            }

            return (
              <Link to={page} key={page}>
                <TextNew.Sans.SM weight={FontWeight.LIGHT} color={Colors.PRIMARY_LIGHT} key={page}>
                  {footerSubLinks[page]}
                </TextNew.Sans.SM>
              </Link>
            );
          })}
        </nav>

        <div className="disclaimer">
          <Row aligncenter justifycenter column>
            <TextNew.Sans.XS
              weight={FontWeight.BOLD}
              color={Colors.PRIMARY_LIGHT}
              localeId="footer.statistic"
            />
            <TextNew.Sans.XS
              weight={FontWeight.LIGHT}
              color={Colors.PRIMARY_LIGHT}
              localeId="footer.disclaimer1Copy"
            />
            <TextNew.Sans.XS
              weight={FontWeight.LIGHT}
              color={Colors.PRIMARY_LIGHT}
              localeId="footer.disclaimer2Copy"
            />
          </Row>
        </div>
        <Row className="ipso">
          <Icon name={IconName.IPSO} width={124} />
          <TextNew.Sans.XS
            weight={FontWeight.LIGHT}
            color={Colors.PRIMARY_LIGHT}
            localeId="footer.ipso"
            localeParams={{
              LINK: (
                <Link key="footer.ipso.link" to={createPath(Pages.CONTACT_US_GET_IN_TOUCH)}>
                  <LocaleMessage id="footer.here" />
                </Link>
              ),
            }}
          />
        </Row>
      </Wrapper.LG>
    </footer>
  );
};

UkPublicFooter.propTypes = {
  isInitializing: PropTypes.bool,
  magazineImage: PropTypes.shape(imageShape),
  host: PropTypes.string,
  /**
   * Footer social media links
   */
  footerSocialLinks: PropTypes.arrayOf(
    PropTypes.shape({
      ...linkPropTypes,
    }),
  ).isRequired,
};

export default withFunctionalClassName('organism','UkPublicFooter', 'isInitializing')(UkPublicFooter);
