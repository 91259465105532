import compose from 'redux/lib/compose';
import { lazy } from 'yup';
import { connect } from 'react-redux';
import { prepareComponent, withInitAction } from '@mediamonks/react-redux-component-init';
import { withFormik } from 'formik';
import moment from 'moment';
import ContactUsFieldNames, {
  ContactUsPublicField,
  ContactUsAdvertisementPublicField,
  ContactUsPublicFormSchema,
  ContactUsPublicAdvertisementFormSchema,
  ContactUsMediaPublicFields,
  ContactUsPublicMediaFormSchema,
} from 'common/src/app/data/enum/FieldNames/ContactUsFieldNames';
import { ContactUsTypeId } from 'common/src/app/data/enum/ContactUsType';
import ContactUsReferences from 'common/src/app/data/enum/ContactUsReferences';
import makeIsLoadingSelector from 'common/src/app/selectors/makeIsLoadingSelector';
import { setSubject } from 'common/src/app/actions/components/contactUsActions';
import sendContact, { SEND_CONTACT } from 'common/src/app/actions/contactActions';
import { ContactFormSubjectIds } from 'common/src/app/data/enum/ContactFormCategory';
import { scrollToElement } from 'common/src/app/actions/scrollActions';
import ContactUsPublicForm from './ContactUsPublicForm';
import GroupSearchCounty from '../../../../../atoms/GroupSearchCounty';

const cleanedUpValues = obj =>
  Object.entries(obj)
    .map(([k, v]) => [k, v && typeof v === 'object' ? cleanedUpValues(v) : v])
    // eslint-disable-next-line no-param-reassign
    .reduce((a, [k, v]) => (v === '' ? a : ((a[k] = v), a)), {});

const isLoadingContactFormSelector = makeIsLoadingSelector(SEND_CONTACT);
const mapStateToProps = (state, { errorBoundaryData, contactType }) => {
  const hasErrorBoundary = errorBoundaryData?.location && errorBoundaryData?.componentName;
  const hasContactType = !!ContactUsTypeId?.[contactType];

  return {
    hasErrorBoundary,
    successMessageSubjectId: state?.view?.components?.contactUs?.subjectId,
    loading: isLoadingContactFormSelector(state),
    contactType,
    hasContactType,
  };
};

const mapDispatchToProps = dispatch => ({
  setSuccessMessage: subjectId => dispatch(setSubject(subjectId)),
  sendMessage: values => dispatch(sendContact(values)),
  scrollToElement: (...args) => dispatch(scrollToElement(...args)),
});

const connected = connect(mapStateToProps, mapDispatchToProps);

const EnhancedContactUsPublicForm = withFormik({
  displayName: 'ContactUsPublicForm',
  enableReinitialize: true,
  mapPropsToValues: ({ contactType }) => {
    let contactFormCategory = '';

    if (ContactUsTypeId?.[contactType]) {
      contactFormCategory = ContactUsTypeId?.[contactType];
    }

    // This is needed otherwise the validation won't work when hitting the submit button
    const formFields = Object.values({
      ...ContactUsAdvertisementPublicField,
      ...ContactUsPublicField,
      ...ContactUsMediaPublicFields,
    }).reduce((fields, field) => ({ ...fields, [field]: '' }), {});

    return {
      contactFormCategory,
      icelandTerms: false,
      message: '',
      ...formFields,
      [ContactUsPublicField.POSTCODE]: '',
    };
  },
  validationSchema: () =>
    lazy(({ contactFormCategory }) => {
      // isContactTypeAdvertisement
      if (contactFormCategory === ContactFormSubjectIds.ADVERTISEMENT_ARTWORK) {
        return ContactUsPublicAdvertisementFormSchema;
      }

      // isContactTypeMedia
      if (contactFormCategory === ContactFormSubjectIds.MEDIA_ENQUIRIES) {
        return ContactUsPublicMediaFormSchema;
      }

      // Default schema
      return ContactUsPublicFormSchema;
    }),
  handleSubmit: async (values, { props, setSubmitting, setErrors }) => {
    const date = values?.date && moment(values?.date).format('YYYY-MM-DD');
    const newValuesObject = cleanedUpValues({ ...values, date });

    const { setSuccessMessage, sendMessage, errorBoundaryData } = props;
    const { contactFormCategory } = newValuesObject;

    let messageForSupport = '';

    if (errorBoundaryData) {
      const { location, componentName } = errorBoundaryData;
      if (location && componentName) {
        messageForSupport = messageForSupport.concat(
          `The component ${componentName} in ${location} has failed\n\r`,
        );
      }
    }

    try {
      await sendMessage({
        ...newValuesObject,
        contactFormCategory: contactFormCategory.replace('id-', ''),
        message: messageForSupport.concat(newValuesObject[ContactUsFieldNames.MESSAGE]),
        reference: ContactUsReferences.PUBLIC,
      });
      await setSuccessMessage(contactFormCategory);
      setSubmitting(false);
    } catch (error) {
      setErrors('validation.errors.general');
      setSubmitting(false);
    }
  },
})(ContactUsPublicForm);

const addInitAction = withInitAction({
  prepared: ({}, dispatch) => dispatch(prepareComponent(GroupSearchCounty)),
});

export default compose(connected, addInitAction)(EnhancedContactUsPublicForm);
